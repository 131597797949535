<template lang="pug">
.login.h-full.bg-gradient-to-b.from-blue-900.to-red-700
  div(
    class="w-full md:max-w-xl xl:max-w-md mx-auto h-full d-flex flex-column justify-center"
  )
    v-card.pa-8.mt-8
      div.my-6.d-flex.justify-center
        img( class="w-24" alt="CampaignArsenal Logo" src="campaign-arsenal-logo.png" )

      h1.text-4xl.text-gray-800.text-center.font-medium Campaign Compliance Made Easy
      div.text-gray-500.text-center.text-base.text-sm.mt-4.mb-8
        | Sign in using the account created for you

      div.mt-6
        v-form(
          v-model="valid"
          @submit.prevent="login()"
        )
          v-text-field(
            append-icon="mdi-email"
            label="Email"
            outlined
            :rules="[rules.required, rules.email]"
            dense
            v-model="loginDetails.email"
          )

          v-text-field(
            append-icon="mdi-lock"
            label="Password"
            outlined
            :rules="[rules.required]"
            dense
            type="password"
            v-model="loginDetails.password"
          )

          div.text-center
            ForgotPasswordDialog

          v-btn.mt-6(
            color="primary"
            block
            type="submit"
            :disabled="!valid"
            depressed
          ) Sign In

</template>

<script>
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import ForgotPasswordDialog from '@/views/users/ForgotPassword.Dialog'
import formValidations from '@/helpers/formValidations'

export default {
  name: 'Login',

  components: {
    ForgotPasswordDialog
  },

  setup(props, { root: { $store, $router } }) {
    if ($store.getters['auth/isAuthenticated'])
      $router.push('/dashboard')

    const { valid, rules } = formValidations()
    const loginError = ref('')
    const loginDetails = ref({
      email: '',
      password: ''
    });
    const updateLogInDate
      = async (id) => $store.dispatch('users/patch', [id, { lastLoggedInAt: new Date() }, {}])

    return {
      valid,
      rules,

      loginError,
      loginDetails,
      updateLogInDate
    }
  },

  methods: {
    ...mapActions('auth', ['authenticate']),

    async login () {
      try {
        const { user: { id } } = await this.authenticate({
          ...this.loginDetails,
          strategy: 'local'
        })
        this.$router.push('/dashboard')
        this.updateLogInDate(id)

      } catch (error) {
        if (error.message === 'Invalid login') {
          this.loginError = 'Your email and/or password are invalid'
        } else {
          this.loginError = error.message
        }
        this.loginDetails.password = ''
        this.$snackError(this.loginError)
      }
    }
  }
}
</script>

<style scoped>
</style>